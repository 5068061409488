/*
Piste à creuser pour choix du template
https://router.vuejs.org/en/essentials/passing-props.html
*/

let routerOptions = {
  mode: 'history',
  routes: [
    // USER QUAND IL N'EST PAS CONNECTE
    {
      path: '',
      component: () => import('@/templates/TemplateUserLogout'),
      meta: { bmsMode: true, authNeedFalse: true },
      children: [
        {
          path: '/',
          component: () => import('@/views/user/logout/UserLogoutDisconnected'),
          name: 'user-disconnected'
        },
        {
          path: '/register',
          component: () => import('@/views/user/logout/UserLogoutRegistration'),
          name: 'user-register'
        },
        {
          path: '/login',
          component: () => import('@/views/user/logout/UserLogoutConnection'),
          name: 'user-login'
        },
        {
          path: '/forgot-password',
          component: () =>
            import('@/views/user/logout/UserLogoutForgotPassword'),
          name: 'user-forgot-password'
        },
        {
          path: '/forgot-validate/:email/:token',
          component: () =>
            import('@/views/user/logout/UserLogoutForgotPasswordValidation'),
          name: 'user-forgot-validate'
        }
      ]
    },

    // USER QUAND IL EST CONNECTE
    {
      path: '',
      component: () => import('@/templates/TemplateUserLogin'),
      meta: { bmsMode: true, authNeedTrue: true },
      children: [
        {
          path: '/profile/:idUser',
          component: () => import('@/views/user/login/UserLoginProfile'),
          name: 'user-profile'
        },
        {
          path: '/home',
          component: () => import('@/views/user/login/UserLoginHome'),
          name: 'user-home',
          // pb du router view children qui ne sont pas render -> remettre le name user list ici pour voir un msg d'erreur
          children: [
            {
              path: '/home',
              components: {
                default: () =>
                  import('@/views/user/login/FunctionalUserListItem'),
                baba: () => import('@/views/user/logout/UserLogoutDisconnected') // ceci est un test
              },
              name: 'user-home'
            }
          ]
        },
        {
          path: '/messenger/:menu/:id',
          component: () => import('@/views/user/login/UserLoginMessenger'),
          name: 'user-messenger'
        },
        {
          path: '/search/:searched',
          component: () => import('@/views/user/login/UserLoginSearch'),
          name: 'user-search'
        },
        {
          path: '/calendar',
          component: () =>
            import('@/views/user/login/UserLoginCalendarStacked'),
          name: 'user-calendar-stacked'
        },
        {
          path: '/calendar/:type',
          component: () => import('@/views/user/login/UserLoginCalendar'),
          name: 'user-calendar'
        }
      ]
    },

    // SMARTPHONE DE L'UTILISATEUR
    // Choix de la salle + Activités
    {
      path: '',
      component: () => import('@/templates/TemplateEventParticipant'),
      meta: { eventMode: true },
      children: [
        {
          path: '/room-selection',
          component: () =>
            import('@/views/event/participant/EventParticipantRoomSelection'),
          name: 'phone-room-selection'
        }
      ]
    },
    {
      path: '',
      component: () => import('@/templates/TemplateEventParticipant'),
      meta: { eventMode: true },
      children: [
        {
          path: '/room-:idRoom/totem',
          component: () =>
            import('@/views/event/participant/totem/EventParticipantTotem'),
          name: 'totem',
          children: []
        },
        {
          path: '/room-:idRoom',
          component: () =>
            import('@/views/event/participant/phone/EventParticipantPhone'),
          name: 'phone',
          children: [
            {
              path: '/room-:idRoom/open-question',
              component: () =>
                import('@/views/event/participant/activity/EventParticipantActivityOpenQuestion'),
              name: 'phone-open-question'
            },
            {
              path: '/room-:idRoom/pixel/:idTask',
              component: () =>
                import('@/views/event/participant/activity/EventParticipantActivityPixel'),
              name: 'phone-pixel'
            },
            {
              path: '/room-:idRoom/ranking',
              component: () =>
                import('@/views/event/participant/activity/EventParticipantActivityRanking'),
              name: 'phone-ranking'
            },
            {
              path: '/room-:idRoom/feed',
              component: () =>
                import('@/views/event/participant/activity/EventParticipantActivityFeed'),
              name: 'phone-feed'
            },
            {
              path: '/room-:idRoom/user-identification/:nextActivity',
              component: () =>
                import('@/views/event/participant/activity/EventParticipantActivityUserIdentification'),
              name: 'phone-user-identification'
            },
            {
              path: '/room-:idRoom/rating',
              component: () =>
                import('@/views/event/participant/activity/EventParticipantActivityRating'),
              name: 'phone-rating'
            },
            {
              path: '/room-:idRoom/external',
              component: () =>
                import('@/views/event/participant/activity/EventParticipantActivityExternalActivity'),
              name: 'phone-external'
            },
            {
              path: '/room-:idRoom/range',
              component: () =>
                import('@/views/event/participant/activity/EventParticipantActivityRange'),
              name: 'phone-range'
            },
            {
              path: '/room-:idRoom/liked-questions',
              component: () =>
                import('@/views/event/participant/activity/EventParticipantActivityLikedQuestions'),
              name: 'phone-liked-questions'
            },
            {
              path: '/room-:idRoom/group',
              component: () =>
                import('@/views/event/participant/activity/EventParticipantActivityGroup'),
              name: 'phone-group'
            }
          ]
        },
        // Choix de la langue
        {
          path: '/lang',
          component: () =>
            import('@/views/event/participant/EventParticipantLang'),
          name: 'phone-lang'
        },
        /*
        {
          path: '/room-:idRoom',
          /* component: () =>
            import('@/views/event/participant/EventParticipantWaitingRoom'),
          name: 'event'
        },
        */
        {
          path: '/room-:idRoom/waiting-room',
          component: () =>
            import('@/views/event/participant/EventParticipantWaitingRoom'),
          name: 'phone-waiting-room'
        },
        {
          path: '/room-:idRoom/waiting-room-reaction/:id',
          component: () =>
            import('@/views/event/participant/EventParticipantWaitingRoomReaction'),
          name: 'phone-waiting-room-reaction'
        },
        {
          path: '/room-:idRoom/survey',
          component: () =>
            import('@/views/event/participant/EventParticipantSurvey'),
          name: 'phone-survey'
        },
        /*
        {
          path: '/room-:idRoom/open-question',
          component: () =>
            import('@/views/event/participant/EventParticipantOpenQuestion'),
          name: 'phone-open-question'
        },
        */
        {
          path: '/room-:idRoom/reaction',
          component: () =>
            import('@/views/event/participant/EventParticipantReaction'),
          name: 'phone-reaction'
        }
        /*
        {
          path: '/room-:idRoom/pixel/:idTask',
          component: () =>
            import('@/views/event/participant/EventParticipantPixel'),
          name: 'phone-pixel'
        }
        {
          path: '/room-:idRoom/ranking',
          component: () =>
            import('@/views/event/participant/EventParticipantRanking'),
          name: 'phone-ranking'
        },
        {
          path: '/room-:idRoom/feed',
          component: () =>
            import('@/views/event/participant/EventParticipantFeed'),
          name: 'phone-feed'
        },
        {
          path: '/room-:idRoom/user-identification',
          component: () =>
            import('@/views/event/participant/EventParticipantUserIdentification'),
          name: 'phone-user-identification'
        }
        */
      ]
    },

    // Routes directes -> Création de cette unité nécessaire pour surcharger les routes dans les configs des app
    // PIP & Bandeau BFM
    {
      path: '',
      component: () => import('@/templates/TemplateEmpty'),
      children: [
        {
          path: '/pip/room-:idRoom/:mode',
          component: () =>
            import('@/views/event/studioManager/EventStudioManagerPip'),
          name: 'screen-pip'
        },
        {
          path: '/bfm/room-:idRoom',
          component: () =>
            import('@/views/event/studioManager/EventStudioManagerBfm'),
          name: 'screen-bfm'
        }
        /*
        {
          path: '/advertisement',
          components: {
            default: () => import('@/templates/TemplateEmpty'),
            logo: () => import('@/views/event/participant/EventParticipantFeed')
          },
          name: 'phone-advertisement'
        }
        */
      ]
    },

    // ECRAN GEANT
    {
      path: '',
      component: () => import('@/templates/TemplateEventStudioManager'),
      meta: { eventMode: true, displayScreen: true },
      children: [
        /*
        {
          path: '/live-reaction',
          component: () =>
            import('@/views/event/studioManager/EventStudioManagerLiveReaction'),
          name: 'screen-live-reaction',
          meta: { switchReactionLive: true }
        },
        */
        {
          path: '',
          component: () =>
            import('@/views/event/studioManager/EventStudioManager'),
          // meta: { switchReactionLive: true },
          children: [
            /*
            {
              path: '/live-reaction',
              component: () =>
                import('@/views/event/studioManager/EventStudioManagerLiveReaction'),
              name: 'screen-live-reaction'
              // meta: { switchReactionLive: true }
            },
            */
            {
              path: '',
              components: {
                /*
                liveReaction: () =>
                  import('@/views/event/studioManager/EventStudioManagerLiveReaction'),
                  */
                childActivity: () =>
                  import('@/views/event/studioManager/EventStudioManagerChildActivity'),
                bfm: () =>
                  import('@/views/event/studioManager/EventStudioManagerBfm'),
                castedReaction: () =>
                  import('@/views/event/studioManager/EventStudioManagerBfm'),
                image: () =>
                  import('@/views/event/studioManager/EventStudioManagerImage'),
                video: () =>
                  import('@/views/event/studioManager/EventStudioManagerVideo'),
                waitingRoom: () =>
                  import('@/views/event/studioManager/EventStudioManagerWaitingRoom'),
                ranking: () =>
                  import('@/views/event/studioManager/EventStudioManagerRanking'),
                question: () =>
                  import('@/views/event/studioManager/EventStudioManagerQuestion'),
                questionWithReplies: () =>
                  import('@/views/event/studioManager/EventStudioManagerQuestionWithReplies'),
                cloud: () =>
                  import('@/views/event/studioManager/EventStudioManagerCloud'),
                verticalChartBar: () =>
                  import('@/views/event/studioManager/EventStudioManagerChartBarVertical'),
                horizontalChartBar: () =>
                  import('@/views/event/studioManager/EventStudioManagerChartBarHorizontal'),
                chartPie: () =>
                  import('@/views/event/studioManager/EventStudioManagerChartPie'),
                pixel: () =>
                  import('@/views/event/studioManager/EventStudioManagerPixel'),
                winningPicture: () =>
                  import('@/views/event/studioManager/EventStudioManagerWinningPicture'),
                userIdentification: () =>
                  import('@/views/event/studioManager/EventStudioManagerUserIdentification'),
                external: () =>
                  import('@/views/event/studioManager/EventStudioManagerExternalActivity'),
                list: () =>
                  import('@/views/event/studioManager/EventStudioManagerList'),
                random_word: () =>
                  import('@/views/event/studioManager/EventStudioManagerRandomWord'),
                liked_questions: () =>
                  import('@/views/event/studioManager/EventStudioManagerLikedQuestions')
              },
              children: [
                {
                  path: '/presentation/room-:idRoom',
                  components: {
                    header: () =>
                      import('@/views/event/studioManager/EventStudioManagerHeader'),
                  },
                  name: 'screen-presentation'
                },
                {
                  path:
                    '/presentation/room-:idRoom/activity-:idActivity/task-:indexTask/:questionOrResult',
                  components: {
                    header: () =>
                      import('@/views/event/studioManager/EventStudioManagerHeader'),
                  },
                  name: 'screen-presentation-task'
                }
              ]
            }
          ]
        },
        {
          path: '/activity/screen/ranking',
          component: () =>
            import('@/views/event/studioManager/EventStudioManagerRanking'),
          name: 'screen-ranking'
        }
      ]
    },

    // with template welcome version marie
    {
      path: '',
      component: () => import('@/templates/TemplateEventAdminWelcome'),
      meta: { eventMode: true },
      children: [
        {
          path: '/admin/rooms',
          components: {
            header: () =>
              import('@/views/event/admin/welcome/EventAdminWelcomeHeader'),
            mainContent: () =>
              import('@/views/event/admin/welcome/EventAdminWelcomeRoomsList')
          },
          name: 'admin-rooms'
        },
        {
          path: '/admin/login',
          components: {
            header: () =>
              import('@/views/event/admin/welcome/EventAdminWelcomeHeader'),
            mainContent: () =>
              import('@/views/event/admin/welcome/EventAdminWelcomeLogin')
          },
          name: 'admin-login'
        }
      ]
    },

    // ADMIN (with template classic)
    {
      path: '',
      component: () => import('@/templates/TemplateEventAdminClassic'),
      meta: { eventMode: true },
      children: [
        {
          path: '',
          component: () =>
            import('@/views/event/admin/classic/EventAdminClassicPresentation'),
          name: 'admin-presentation',
          children: [
            {
              path: '/room-:idRoom/admin/presentation',
              components: {
                main: () =>
                  import('@/views/event/admin/classic/EventAdminClassicPresentationContent'),
                settings: () =>
                  import('@/views/event/admin/classic/EventAdminClassicPresentationContentSettings')
              },
              name: 'admin-presentation-content'
            },
            {
              path: '/room-:idRoom/admin/presentation/:idActivity',
              components: {
                main: () =>
                  import('@/views/event/admin/classic/EventAdminClassicPresentationContent'),
                settings: () =>
                  import('@/views/event/admin/classic/EventAdminClassicPresentationContentSettings')
              },
              name: 'admin-presentation-content-liberty'
            },
            {
              path: '/admin/presentation/design',
              components: {
                main: () =>
                  import('@/views/event/admin/classic/EventAdminClassicPresentationDesign'),
                settings: () =>
                  import('@/views/event/admin/classic/EventAdminClassicPresentationDesignSettings')
              },
              name: 'admin-presentation-design'
            }
          ]
        },
        {
          path: '/room-:idRoom/admin/moderation',
          component: () =>
            import('@/views/event/admin/classic/EventAdminClassicModeration'),
          name: 'admin-moderation'
        },
        {
          path: '/room-:idRoom/speaker/moderation',
          component: () =>
            import('@/views/event/admin/classic/EventAdminClassicModeration'),
          name: 'speaker-moderation'
        },
        {
          path: '/room-:idRoom/admin/activity',
          component: () =>
            import('@/views/event/admin/classic/EventAdminClassicActivity'),
          name: 'admin-activity'
        },
        {
          path: '/room-:idRoom/admin/customization',
          component: () =>
            import('@/views/event/admin/classic/EventAdminClassicCustomization'),
          name: 'admin-customization'
        },
        {
          path: '/room-:idRoom/admin/waiting-room',
          component: () =>
            import('@/views/event/admin/classic/EventAdminClassicWaitingRoom'),
          name: 'admin-waiting-room',
          children: [
            {
              path: 'page/:idPage',
              component: () =>
                import('@/views/event/admin/classic/EventAdminClassicWaitingRoomPageConfig'),
              name: 'admin-waiting-room-page-config'
            },
          ]
        }
      ]
    },



    // TABLETTE INTERVENANT
    {
      path: '',
      component: () => import('@/templates/TemplateEventSpeaker'),
      meta: { eventMode: true },
      children: [
        {
          path: '',
          components: {
            default: () => import('@/views/event/speaker/EventSpeakerSlides'),
            controls: () =>
              import('@/views/event/speaker/EventSpeakerControls'),
            comments: () => import('@/views/event/speaker/EventSpeakerComments')
          },
          children: [
            {
              path: '/speaker/room-:idRoom',
              components: {
                image: () =>
                  import('@/views/event/studioManager/EventStudioManagerImage'),
                question: () =>
                  import('@/views/event/studioManager/EventStudioManagerQuestion'),
                cloud: () =>
                  import('@/views/event/studioManager/EventStudioManagerCloud'),
                chartBar: () =>
                  import('@/views/event/studioManager/EventStudioManagerChartBarVertical'),
                chartPie: () =>
                  import('@/views/event/studioManager/EventStudioManagerChartPie'),
                pixel: () =>
                  import('@/views/event/studioManager/EventStudioManagerPixel')
              },
              name: 'speaker'
            }
          ]
        }
      ]
    },

    {
      path: '/admin',
      redirect: '/admin/login'
    } /*
    ,
    {
      path: '/admin/presentation',
      redirect: '/admin/rooms'
    },
    {
      path: '/admin/activity',
      redirect: '/admin/rooms'
    }
    */
    /*,
    {
      path: '*',
      redirect: '/'
    }
    */
  ]
}

export default routerOptions
