import Vue from 'vue'
import App from './App.vue'
import specific from './assets/text/specific'
import { specificSettings, specificTheme, specificRoutes } from './config'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueRouter from 'vue-router'
// import VueAuthenticate from 'vue-authenticate'
import Vuetify from 'vuetify'
import VueTouch from 'vue-touch'
import VueMoment from 'vue-moment'
import moment from 'moment'
import VueCookie from 'vue-js-cookie'
import Vuex from 'vuex'
import { stateMerge } from 'vue-object-merge'
import store from '@/base/store'
import routerOptions from '@/base/router'
import VueI18n from 'vue-i18n'
// import language from '@/translations/fr'
import arrayUnique from '@/utils/arrayUnique'
/* eslint-disable-next-line */
import filters from '@/utils/filters'
/* eslint-disable-next-line */
import constant from '@/base/constant'
import RoomApi from '@/api/room'


async function initVueApp() {
  Vue.use(VueAxios, axios)
  Vue.use(Vuex)

  // Création des settings de l'app
  Vue.prototype.$settings = specificSettings
  axios.defaults.baseURL = Vue.prototype.$settings.global.apiUrl
  // axios.defaults.headers.common['authorization'] = `Bearer test`;
  store.dispatch('setEventMode', specificSettings.global.eventMode)
  store.dispatch('setBmsMode', specificSettings.global.bmsMode)

  // Surcharge du router avec des configs spécifiques à l'app (ex surcharge d'un component)
  var i = 0
  for (i = 0; i < routerOptions.routes.length; i++) {
    if (specificRoutes[i] !== undefined && specificRoutes[i].length > 0) {
      routerOptions.routes[i].children = arrayUnique([
        ...specificRoutes[i],
        ...routerOptions.routes[i].children
      ])
    }
  }

  // Surcharge du router avec des personnalisations utilisateur (ex nom des différentes rooms)
  var nbRoom = 1
  await RoomApi.getRoutesOfRooms().then(response => {
    nbRoom = response.length
    var i = 0
    if (nbRoom >= 1) {
      for (i = 0; i < nbRoom; i++) {
        if (response[i].roomUrl !== null) {
          routerOptions.routes.push({
            path: response[i].roomUrl + '/presentation',
            redirect: '/presentation/room-' + response[i].idRoom
          })
          routerOptions.routes.push({
            path: response[i].roomUrl,
            redirect: '/room-' + response[i].idRoom
          })
        }
      }
    }
    if (nbRoom === 1) {
      routerOptions.routes.push({
        path: '/presentation',
        redirect: '/room-1/presentation'
      })
      routerOptions.routes.push({
        path: '/admin/activity',
        redirect: '/room-1/admin/activity'
      })
      routerOptions.routes.push({
        path: '/admin/presentation',
        redirect: '/room-1/admin/presentation'
      })
      routerOptions.routes.push({
        path: '/admin/moderation',
        redirect: '/room-1/admin/moderation'
      })
      routerOptions.routes.push({
        path: '/admin/customization',
        redirect: '/room-1/admin/customization'
      })
      routerOptions.routes.push({
        path: '/',
        redirect: '/room-1'
      })
      routerOptions.routes.push({
        path: '*',
        redirect: '/room-1'
      })
    } else {
      routerOptions.routes.push({
        path: '*',
        redirect: '/room-selection'
      })
      /* pourra être suppr plus tard, utile maintenant car url mémorisés par nos navigateurs */
      routerOptions.routes.push({
        path: '/admin/activity',
        redirect: '/admin/rooms'
      })
      routerOptions.routes.push({
        path: '/admin/presentation',
        redirect: '/admin/rooms'
      })
      routerOptions.routes.push({
        path: '/admin/moderation',
        redirect: '/admin/rooms'
      })
      routerOptions.routes.push({
        path: '/admin/customization',
        redirect: '/admin/rooms'
      })
      /* fin trucs à suppr */
    }
  })

  // console.log('routes', routerOptions.routes)

  let router = new VueRouter(routerOptions)

  Vue.use(VueRouter)
  Vue.use(VueTouch)
  Vue.use(Vuetify, specificTheme)
  Vue.use(VueMoment, {
    moment
  })
  Vue.use(VueCookie)

  var lang = window.navigator.language.substr(0, 2)
  // var lang = 'en'
  moment.locale(lang)
  Vue.use(VueI18n)

  const i18n = new VueI18n({
    locale: lang,
    fallbackLocale: 'en',
    messages: {}
  })

  import(`@/translations/${lang}.json`).then(msgs => {
    stateMerge(msgs.default, specific)
    i18n.setLocaleMessage(lang, msgs.default || msgs)
    // i18n.locale = lang
    // this.$moment.locale(locale)
    // this.$i18n.locale = locale
  })
  new Vue({ i18n }).$mount('#app')

  Vue.prototype.$eventHub = new Vue()

  /* eslint-disable-next-line */
  var vm = new Vue({
    el: '#app',
    store,
    router,
    i18n,
    render: h => h(App)
  })

  router.beforeResolve((to, from, next) => {
    if (to.matched.some(record => record.meta.bmsMode)) {
      if (store.getters.eventMode) {
        if (nbRoom === 1) {
          next({
            path: '/room-1'
          })
        } else {
          next({
            path: '/room-selection'
          })
        }
      } else {
        if (to.matched.some(record => record.meta.authNeedTrue)) {
          if (!(store.getters.idUser > 0)) {
            next({
              path: '/user-disconnected',
              query: { redirect: to.fullPath }
            })
          }
        } else if (to.matched.some(record => record.meta.authNeedFalse)) {
          if (store.getters.idUser > 0) {
            next({
              path: '/home',
              query: { redirect: to.fullPath }
            })
          }
        }
      }
    }
    // make sure to always call next()!
    next()
  })
}

initVueApp()
