import {
  apiUrlByDefault,
  socketUrlByDefault,
  cssUrlByDefault
} from '@config/urlByDefault'

export const appName = 'saintex'

export const specificSettings = {
  global: {
    appName: appName,
    apiUrl: apiUrlByDefault + appName + '/v1/',
    socketUrl: socketUrlByDefault,
    cssUrl: cssUrlByDefault,
    cookieDuration: 90,
    bmsMode: false,
    eventMode: true,
    screenMode: false
  }
}

export const specificTheme = {
  theme: {
    primary: '#000', // #f8ac38
    secondary: '#ec164e',
    accent: '#2ed6bc',
    error: '#f44336',
    warning: '#ffeb3b',
    info: '#2196f3'
  }
}

export const specificRoutes = [
  // UserLogout
  [],
  // UserLogin
  [],
  // EventLanguageChoice
  [],
  // EventParticipant
  [],
  // Empty
  [],
  // EventStudioManager
  [],
  // EventAdminWelcome
  [],
  // EventAdminClassic
  [],
  // EventSpeaker
  []
]
