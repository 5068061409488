// Mode web

export const apiUrlByDefault = 'https://api.prod2.blackmoonlab.com/'
export const socketUrlByDefault = 'https://socket.marie.blackmoonlab.com/'
export const cssUrlByDefault = 'https://css.blackmoonlab.com/'

// Mode private network

/* export const apiUrlByDefault = 'http://api.bml.com/'
export const socketUrlByDefault = 'http://socket.bml.com:8086'
export const cssUrlByDefault = 'http://css.bml.com/' */

 // Serveur de dev
/* export const apiUrlByDefault = 'http://api.romain.blackmoonlab.com/'
export const socketUrlByDefault = 'http://socket.dev.blackmoonlab.com/'
export const cssUrlByDefault = 'http://css.dev.blackmoonlab.com/' */
