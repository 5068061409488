<script>
import { appMixin } from '@/mixins/App'

export default {
  mixins: [appMixin]
}
</script>

<style lang="scss">
@import '~./assets/style/specific.scss';
</style>
