import UserApi from '@/api/user'
import AppConfig from '@/api/appConfig'
// import ToolboxApi from '@/api/toolbox'

/* eslint-disable-next-line */
export const appMixin = {
  template: `
    <v-app id="inspire">
        <router-view />
    </v-app>
    `,
  created() {
    if (!this.$cookie.get('userSession') || !this.$cookie.get('idUser')) {
      UserApi.createToken().then(response => {
        // création de cookie : nomCookie, valCookie, durée de validité en jours
        // https://github.com/BlueBayTravel/vue-js-cookie
        this.$cookie.set(
          'idUser',
          response.idUser,
          this.$settings['global']['cookieDuration']
        )
        this.$cookie.set(
          'userSession',
          response.token,
          this.$settings['global']['cookieDuration']
        )
      })
    } else if (this.$cookie.get('idUser') > 0) {
      this.$store.dispatch('setTemplate', 'T_LOGIN')
      this.$store.dispatch('setIdUser', this.$cookie.get('idUser'))
      this.$store.dispatch('setUserSession', this.$cookie.get('userSession'))
    }

    AppConfig.getAppConfig()
    .then(response => {
      this.$store.dispatch('fetchAppConfig', response)
    })

    /*
    // Tester si on injecte le css ou pas
    ToolboxApi.checkIfFileExists({
      fileUrl: this.$settings['global']['cssUrl'] + 'msamam'
    }).then(response => {
      if (response === true) {
        // le fichier css existe donc on l'ajoute à la page
        var link = document.createElement('link')
        link.rel = 'stylesheet'
        link.href = this.$settings['global']['cssUrl'] + 'msamam'
        document.getElementsByTagName('head')[0].appendChild(link)
      }
    })
    */

    // this.$store.dispatch('setActivityMode',1)
    // this.$router.push({ name: 'user-disconnected' })

    /*
        Api.getUsers().then(response => {
          //this.loading = false;
          console.log(response.data);
        }, error => {
          //this.loading = false;
          console.error(error);
        });
        */
    // test
  }
}
