import axios from 'axios'
// axios.defaults.headers.common['authorization'] = `Bearer test`;

export default {
  getUsers() {
    return axios.get('user/all').then(
      response => {
        return response.data
      },
      error => {
        console.log('error', error)
      }
    )
  },

  getUsersResearched(searched) {
    return axios.get('user/search/' + JSON.stringify(searched)).then(
      response => {
        return response.data
      },
      error => {
        console.log('error', error)
      }
    )
  },

  getUserLite(idUser) {
    return axios.get('user/' + idUser + '/lite').then(
      response => {
        return response.data
      },
      error => {
        console.log('error', error)
      }
    )
  },

  getUser(params) {
    return axios.get('user/' + JSON.stringify(params)).then(
      response => {
        return response.data
      },
      error => {
        console.log('error', error)
      }
    )
  },

  getUserMatching(params) {
    return axios.get('user/matching/' + JSON.stringify(params)).then(
      response => {
        return response.data
      },
      error => {
        console.log('error', error)
      }
    )
  },

  checkNotifications() {
    return axios
      .get('user/check-notifications/' + this.$store.getters.idUser)
      .then(
        response => {
          return response.data
        },
        error => {
          console.log('error', error)
        }
      )
  },

  updateUser(params) {
    return axios.post('user/' + this.$store.getters.idUser, params).then(
      response => {
        return response.data
      },
      error => {
        console.log('error', error)
      }
    )
  },

  getEventsForAUser(idUser) {
    return axios.post('user/event/' + JSON.stringify({ idUser: idUser })).then(
      response => {
        return response.data
      },
      error => {
        console.log('error', error)
      }
    )
  },

  registerForAnEvent(idEvent, idUser, participation) {
    return axios
      .post('user/register-event', {
        idEvent: idEvent,
        idUser: idUser,
        participation: participation
      })
      .then(
        response => {
          return response.data
        },
        error => {
          console.log('error', error)
        }
      )
  },

  registerTimeslotForAnEvent(idTimeslot, idUser) {
    return axios
      .post('user/register-timeslot', {
        idTimeslot: idTimeslot,
        idUser: idUser
      })
      .then(
        response => {
          return response.data
        },
        error => {
          console.log('error', error)
        }
      )
  },

  unregisterTimeslotForAnEvent(idTimeslot, idUser) {
    return axios
      .post('user/no-register-timeslot', {
        idTimeslot: idTimeslot,
        idUser: idUser
      })
      .then(
        response => {
          return response.data
        },
        error => {
          console.log('error', error)
        }
      )
  },

  savePreferencesCalendar(preferences, idUser) {
    return axios
      .post('user/calendar/save-preferences', {
        preferences: preferences,
        idUser: idUser
      })
      .then(
        response => {
          return response.data
        },
        error => {
          console.log('error', error)
        }
      )
  },

  initRegistration() {
    return axios.get('user/init-register').then(
      response => {
        return response.data
      },
      error => {
        console.log('error', error)
      }
    )
  },

  checkEmail(email) {
    return axios
      .get('user/check-email/' + JSON.stringify({ email: email }))
      .then(
        response => {
          return response.data
        },
        error => {
          console.log('error', error)
        }
      )
  },

  registerUser(register) {
    return axios
      .post('user/register', {
        register: register
      })
      .then(
        response => {
          return response.data
        },
        error => {
          console.log('error', error)
        }
      )
  },

  updateAvatar(idUser, data) {
    return axios
      .post('user/pic/' + idUser, data, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      .then(
        response => {
          return response.data
        },
        error => {
          console.log('error', error)
        }
      )
  },

  tryToLoginUser(email, password) {
    return axios
      .post('user/login', {
        // email: reply.id,
        // password: reply.password
        email: email,
        password: password
      })
      .then(
        response => {
          return response.data
        },
        error => {
          console.log('error', error)
        }
      )
  },

  forgotPasswordValidation(email, password, resetPwToken) {
    return axios
      .post('user/forgot/' + email, {
        password: password,
        resetPwToken: resetPwToken
      })
      .then(
        response => {
          return response.data
        },
        error => {
          console.log('error', error)
        }
      )
  },

  forgotPassword(email) {
    return axios.get('user/forgot/' + email).then(
      response => {
        return response.data
      },
      error => {
        console.log('error', error)
      }
    )
  },

  createToken() {
    return axios.get('user/createToken').then(
      response => {
        return response.data
      },
      error => {
        console.log('error', error)
      }
    )
  },

  /* Messenger */
  getUserMessages(idChat) {
    return axios
      .get('user/messenger/' + JSON.stringify({ idChat: idChat }))
      .then(
        response => {
          return response.data
        },
        error => {
          console.log('error', error)
        }
      )
  },

  getMessengerIndex(idUser) {
    return axios
      .get('user/messenger/' + JSON.stringify({ idUser: idUser, index: true }))
      .then(
        response => {
          return response.data
        },
        error => {
          console.log('error', error)
        }
      )
  },

  sendFirstMessengerMessage(idUser, reply, idUserOtherParticipant) {
    return axios
      .post('user/messenger', {
        idChat: -1,
        idUser: idUser,
        reply: reply,
        idUserOtherParticipant: idUserOtherParticipant
      })
      .then(
        response => {
          return response.data
        },
        error => {
          console.log('error', error)
        }
      )
  },

  sendMessengerMessage(idChat, idUser, reply) {
    return axios
      .post('user/messenger', {
        idChat: idChat,
        idUser: idUser,
        reply: reply
      })
      .then(
        response => {
          return response.data
        },
        error => {
          console.log('error', error)
        }
      )
  },

  updateLastReadingMessenger(idChat, idUser) {
    return axios
      .post('user/messenger-last-reading', {
        idUser: idUser,
        idChat: idChat
      })
      .then(
        response => {
          return response.data
        },
        error => {
          console.log('error', error)
        }
      )
  },

  checkIdChatForNewMessengerMessage(idUser, idUserOtherParticipant) {
    return axios
      .get(
        'user/messenger-check/' +
          JSON.stringify({
            idUser: idUser,
            idUserOtherParticipant: idUserOtherParticipant
          })
      )
      .then(
        response => {
          return response.data
        },
        error => {
          console.log('error', error)
        }
      )
  },

  /* Attribute */
  deleteHasAttribute(idHasAttribute) {
    return axios
      .post('user/delete-has-attribute', { idHasAttribute: idHasAttribute })
      .then(
        response => {
          return response.data
        },
        error => {
          console.log('error', error)
        }
      )
  },

  addHasAttribute(idUser, idAttribute, value) {
    return axios
      .post('user/add-has-attribute', {
        idUser: idUser,
        idAttribute: idAttribute,
        value: value
      })
      .then(
        response => {
          return response.data
        },
        error => {
          console.log('error', error)
        }
      )
  },

  /* Calendar */
  makeReservation(reply) {
    return axios.post('user/calendar/make-reservation', { reply: reply }).then(
      response => {
        return response.data
      },
      error => {
        console.log('error', error)
      }
    )
  },

  getCalendar(idCalendar, idAttribute) {
    return axios
      .get(
        'user/calendar/' +
          JSON.stringify({ idCalendar: idCalendar, idAttribute: idAttribute })
      )
      .then(
        response => {
          return response.data
        },
        error => {
          console.log('error', error)
        }
      )
  },

  getCalendarReservations(idsCalendar, idUser, type) {
    return axios
      .get(
        'user/calendar-reservations/' +
          JSON.stringify({
            idsCalendar: idsCalendar,
            idUser: idUser,
            type: type
          })
      )
      .then(
        response => {
          return response.data
        },
        error => {
          console.log('error', error)
        }
      )
  },

  getCalendarAvailabilities(idsCalendar, date) {
    return axios
      .get(
        'user/calendar-stacked/' +
          JSON.stringify({
            idsCalendar: idsCalendar,
            date: date
          })
      )
      .then(
        response => {
          return response.data
        },
        error => {
          console.log('error', error)
        }
      )
  },

  getCalendarPlanning(idsCalendar) {
    return axios
      .get(
        'user/planning-reservations/' +
          JSON.stringify({
            idsCalendar: idsCalendar
          })
      )
      .then(
        response => {
          return response.data
        },
        error => {
          console.log('error', error)
        }
      )
  },

  reorganizeCalendarReservation(reply) {
    return axios
      .post('user/calendar/reorganize-reservations', { reply: reply })
      .then(
        response => {
          return response.data
        },
        error => {
          console.log('error', error)
        }
      )
  },

  deleteCalendarReservation(idReservation) {
    return axios
      .post('user/delete-calendar-reservation', {
        idReservation: idReservation
      })
      .then(
        response => {
          return response.data
        },
        error => {
          console.log('error', error)
        }
      )
  }
}
