export default function arrayUnique(array) {
  var a = array.concat()
  for (var i = 0; i < a.length; i++) {
    for (var j = i + 1; j < a.length; j++) {
      if (a[i].path === a[j].path && a[i].name === a[j].name) {
        a.splice(j, 1)
      }
    }
  }
  return a
}
